<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="spinner-border mt-3" style="width: 5rem; height: 5rem;color:#008D36" role="status">
          <span class="sr-only">Creando...</span>
        </div> 
        <h2 class="mb-1 mt-3">
          Por favor danos unos segundos, estamos creando tu cuenta
        </h2>              
        <b-img class="mt-3"
            fluid
            :src="imgUrl"
            alt="Login V2"
        />
      </div>
    </div>
  </div>

</template>


<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BSpinner,BRow, BCol } from 'bootstrap-vue'
import store from '@/store/index'
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {        
      BLink,
      BButton,
      BImg,
      BSpinner,
      BRow,
      BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/sosty-login.png'),
      redirectToProjectCode: null,
      email: null,
      user:{},
      projectCode: null
      }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectCode != null) {
    this.projectCode = this.$route.query.projectCode;
    }

    if(this.$route != null && this.$route.query != null && this.$route.query.email != null) {
    this.email = this.$route.query.email;
    }
    setTimeout(() => {      
      try {
          fbq('track', 'CompleteRegistration');
      }
      catch(err) {
          
      }        
      this.loginFromThankYouPage();        
    },5000) 
  },

  computed: {
      imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {            
          this.downImg = require('@/assets/images/pages/sosty-login.png')
          return this.downImg
      }
      return this.downImg
      },
  },

  methods:{
    loginFromThankYouPage(){
      this.processing = true;                  
      var payload = {
        email: this.email
      }
      axios.post('/User/LoginFromThankYouPage', payload)
        .then(response => {
          this.processing = false;
          this.data = response.data;
          useJwt.setToken(response.data.accessToken)
          var abilities = getAbilitiesBasedOnUserType(response.data.user.userType);
          response.data.user.ability = abilities;
          localStorage.setItem('userData', JSON.stringify(response.data.user));
          this.$ability.update(abilities);
          this.$router.replace({path: 'featured-project'});
      })
    }, 
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
